import {
	ButtonContainer,
	MessageDiv,
	StyledError,
	StyledGap,
} from '../../../styles/formularz';
import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';
import { StyledArticle, StyledButton } from '../../../styles';

import { Layout } from '../../../components/Layout';
import { StyledForm } from '../../../styles/przetarg';
import { StyledH2 } from '../../../styles/common';
import axios from '../../../config/axios';
import { navigate } from 'gatsby';
import { useAuth } from '../../../hooks/useAuth';
import { SEO } from '../../../components/SEO';

export const Head = () => (
	<SEO 
	title='Przetarg - usuwanie'
	description='Usuwanie przetargu.'
	/>);

const isBrowser = () => typeof window !== 'undefined';

const UsunPage = () => {
	const auth = isBrowser()
		? useAuth()
		: { user: '', role: '', verifyUser: () => {}, signOut: () => {} };

	const [id, setId] = useQueryParam('id', StringParam);
	const [error, setError] = useState('');

	useEffect(() => {
		auth.verifyUser();
		const token = localStorage.getItem('token');
		if (!token) {
			navigate('/konto/logowanie/');
		}
		document.title = `Przetarg - usuwanie ${id.replace(/_/g, '/')}`
	}, []);

	const deleteTender = async (e: any) => {
		e.preventDefault();
		if (!id) {
			return;
		}

		try {
			const response = await axios.post('/delete_tender.php', { id });
			if (response?.status === 204) {
				navigate('/konto/przetargi/lista');
			}
		} catch (e) {
			console.log(e.response?.status);
			if (e.response?.status === 400) {
				setError(`Nieprawidłowe dane w formularzu.`);
				scroll(0, 0);
			}
			if (e.response?.status === 404) {
				setError(`Nie znaleziono przetargu.`);
				scroll(0, 0);
			}
			if (e.response?.status === 401) {
				auth.signOut();
			}
		}
	};

	if (!auth.user || !id) {
		return null;
	}

	return (
		<Layout>
			<StyledArticle>
				<StyledH2 as="h1" color="info">
					{`Przetarg - usuwanie ${id.replace(/_/g, '/')}`}
				</StyledH2>
				<MessageDiv>
					<StyledForm>
						{error ? <StyledError>{error}</StyledError> : <StyledGap />}
						<div>Czy chcesz usunąć przetarg {id.replace(/_/g, '/')} ?</div>
						<ButtonContainer>
							<StyledButton
								type="button"
								onClick={() => {
									navigate('/konto/przetargi/lista');
								}}
							>
								Anuluj
							</StyledButton>
							<StyledButton
								type="button"
								onClick={deleteTender}
								marginleft="16px"
							>
								Usuń
							</StyledButton>
						</ButtonContainer>
					</StyledForm>
				</MessageDiv>
			</StyledArticle>
		</Layout>
	);
};

export default UsunPage;
